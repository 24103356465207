import {Injectable} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {Observable} from 'rxjs';

import {ApiService} from '../../shared/core/api.service';
import {
  MapDataIssue,
  MapPreview,
  MapPreviewCreateRequest,
  ProgramAdditionalInfoLinkUpdateRequest,
  ProgramAdvisingLinkUpdateRequest,
  ProgramMap,
  ProgramMapBatchMoveRequest,
  ProgramMapCreateRequest,
  ProgramWithMap,
  PublishedCourseListSummary,
  PublishedCourseSummary,
  PublishedGeneralEducationAreaSummary,
  PublishedProgramSummary
} from '../../shared/programmapper-authoring.model';
import {isNullOrUndefined} from "util";


@Injectable({
  providedIn: 'root'
})
export class MapsService {

  private _unsavedMapState: MapPreviewCreateRequest = undefined;
  private _errors: MapDataIssue[];

  constructor(private apiService: ApiService) {
  }

  getPublishedProgram(masterRecordId: string): Observable<PublishedProgramSummary> {
    if(isNullOrUndefined(masterRecordId)){
        throw new Error("master record id cannnot be undefined");
      }
    return this.apiService.getCollege<PublishedProgramSummary>(`programs/${masterRecordId}`);
  }

  getProgramWithMap(masterRecordId: string): Observable<ProgramWithMap> {
    //if(!this.programsWithMaps.has(masterRecordId)){
      return this.apiService.getCollege<ProgramWithMap>(`mapped-programs/${masterRecordId}`);
    // }
    // else {
    //   return of(this.programsWithMaps.get(masterRecordId))
    // }
  }

  // TODO: allow addition of a call to action to a program

  // updateProgram(program: MappedProgramForAuthoring): Observable<ProgramForAuthoring> {
  //   return this.apiService.put<ProgramForAuthoring>(program, `programs/${program.id}`);
  // }

  getProgramTransferToColleges(masterRecordId: string): Observable<string[]> {
    return this.apiService.getCollege<string[]>(`programs/${masterRecordId}/transfer-to-destinations`);
  }

  getMap(uuid: string): Observable<ProgramMap> {
    return this.apiService.getCollege(`program-maps/${uuid}`);
  }

  getCourses(): Observable<PublishedCourseSummary[]> {
    return this.apiService.getCollege(`courses`);
  }

  getCoursesForProgram(programId: string): Observable<PublishedCourseSummary[]> {
    return this.apiService.getCollege(`${programId}/courses`);
  }

  getCourseLists(): Observable<PublishedCourseListSummary[]> {
    return this.apiService.getCollege(`course-lists`);
  }

  getCourseListsForProgram(programId: string): Observable<PublishedCourseListSummary[]> {
    return this.apiService.getCollege(`${programId}/course-lists`);
  }

  getGenEdAreas(): Observable<PublishedGeneralEducationAreaSummary[]> {
    return this.apiService.getCollege(`gen-ed-areas`);
  }


  getGenEdAreasForProgram(programId: string): Observable<PublishedGeneralEducationAreaSummary[]> {
    return this.apiService.getCollege(`${programId}/gen-ed-areas`);
  }

  createMap(map: ProgramMapCreateRequest): Observable<ProgramMap> {
    return this.apiService.postCollege<ProgramMap, ProgramMapCreateRequest>(map, `program-maps`);
  }

  copyMapToProgram(mapId: String, programId: string): Observable<ProgramMap> {
    return this.apiService.postCollege( {}, `program-map-copy/${mapId}/${programId}`);
  }

  updateMap(map: ProgramMap): Observable<ProgramMap | HttpErrorResponse> {
    return this.apiService.putCollege<ProgramMap>(map, `program-maps/${map.id}`);
  }

  hasUnsavedMapState(){
    return ! isNullOrUndefined(this._unsavedMapState);
  }

  get unsavedMapState(): [MapPreviewCreateRequest, MapDataIssue[]] {
    return [this._unsavedMapState, this._errors];
  }

  clearUnsavedMapState() {
    this._unsavedMapState = undefined;
  }

  moveMaps(programMapBatchMoveRequest: ProgramMapBatchMoveRequest) : Observable <any>{
    return this.apiService.postCollege(programMapBatchMoveRequest, `program-maps-batch-move`);
  }

  deleteMap(mapId: string): Observable<boolean> {
    return this.apiService.deleteCollege(`program-maps/${mapId}`);
  }

  postMapPreview(map: MapPreviewCreateRequest): Observable<MapPreview> {
    this.setUnsavedMapState(map, []);
    return this.apiService.postCollege<MapPreview, MapPreviewCreateRequest>(map, `map-previews`);
  }

  getMapPreview(id: string): Observable<MapPreview> {
    return this.apiService.getCollege(`map-previews/${id}`);
  }

  getMappedPrograms(): Observable<ProgramWithMap[]> {
    return this.apiService.getCollege<ProgramWithMap[]>(`mapped-programs`);
  }

  setUnsavedMapState(formData: MapPreviewCreateRequest, errors: MapDataIssue[]) {
    this._unsavedMapState = formData;
    this._errors = errors;
  }

  updateProgramAdvisingLink(programId: string, advisingLink: string) {
    return this.apiService.postCollege<boolean, ProgramAdvisingLinkUpdateRequest>(
      {
        advisingLink : advisingLink
      },
      `mapped-programs/${programId}/advising-link`
    );
  }

  updateProgramAdditionalInfoLink(programId: string, infoLink: string) {
    return this.apiService.postCollege<boolean, ProgramAdditionalInfoLinkUpdateRequest>(
      {
        additionalInfoLink: infoLink
      },
      `mapped-programs/${programId}/additional-info-link`
    )
  }
}
