import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';

import {
  PublishedCourseSummary
} from "../../../../shared/programmapper-authoring.model";
import {isNullOrUndefined} from "util";

@Injectable({
  providedIn: 'root'
})
export class CourseSelectService {

  selectedCourse: PublishedCourseSummary;
  private minUnits : number;
  private maxUnits : number;
  private lectureHours : number;
  private labHours : number;
  private minHours : number;
  private maxHours : number;

  selectedCourseAndUnits$ : Subject<SelectedCourseWithUnits> = new Subject();

  allSelectedCurriculumItemsByMasterRecordId$: BehaviorSubject<string[]> = new BehaviorSubject([]);

  initialize(selectedMasterRecordIds: string[]) {
    this.selectedCourse = undefined;
    this.allSelectedCurriculumItemsByMasterRecordId$.next(selectedMasterRecordIds);
  }

  // min and max units are the same - no need to select card units
  selectCourseWithDefaultUnits(curriculumElementSummary: PublishedCourseSummary): void {
    if(curriculumElementSummary.minUnits !== curriculumElementSummary.maxUnits){
      throw new Error("min and max units must be the same to use this method.");
    }
    if(isNaN(curriculumElementSummary.minUnits) || isNaN(curriculumElementSummary.maxUnits)){
      throw new Error("min and max units must be numbers");
    }
    this.selectedCourse = curriculumElementSummary;
    this.minUnits = Number(curriculumElementSummary.minUnits).valueOf();
    this.maxUnits = Number(curriculumElementSummary.maxUnits).valueOf();
    this.lectureHours = Number(curriculumElementSummary.lectureHours).valueOf();
    this.labHours = Number(curriculumElementSummary.labHours).valueOf();
    this.minHours = Number(curriculumElementSummary.lectureHours != 0 ? curriculumElementSummary.lectureHours : curriculumElementSummary.labHours).valueOf();
    this.maxHours = Number(curriculumElementSummary.lectureHours != 0 ? curriculumElementSummary.lectureHours : curriculumElementSummary.labHours).valueOf();
    this.selectedCourseAndUnits$.next({course:curriculumElementSummary, minUnits: this.minUnits, maxUnits:this.maxUnits, lectureHours: this.lectureHours, labHours: this.labHours, minHours: this.minHours, maxHours: this.maxHours});


    this.updateAllSelectedItems(curriculumElementSummary);
  }

  selectCourseWithDeferredUnitsSelection(curriculumElementSummary: PublishedCourseSummary) {
    this.maxUnits = undefined;
    this.minUnits = undefined;
    this.lectureHours = Number(curriculumElementSummary.lectureHours).valueOf();
    this.labHours = Number(curriculumElementSummary.labHours).valueOf();
    this.minHours = Number(curriculumElementSummary.lectureHours != 0 ? curriculumElementSummary.lectureHours : curriculumElementSummary.labHours).valueOf();
    this.maxHours = Number(curriculumElementSummary.lectureHours != 0 ? curriculumElementSummary.lectureHours : curriculumElementSummary.labHours).valueOf();
    this.selectedCourse = curriculumElementSummary;
  }

  selectUnitsForSelectedCourse(selectedMinUnit: number, selectedMaxUnit: number) {
    if(isNullOrUndefined(this.selectedCourse)){
      throw new Error("must select a curriculum element first before setting units for it");
    }
    if(isNaN(selectedMinUnit) || isNaN(selectedMaxUnit)){
      throw new Error("min and max units must be numbers");
    }
    this.maxUnits = Number(selectedMaxUnit).valueOf();
    this.minUnits = Number(selectedMinUnit).valueOf();
    this.selectedCourseAndUnits$.next({course:this.selectedCourse, minUnits:this.minUnits, maxUnits:this.maxUnits, lectureHours:0, labHours: 0, minHours: 0, maxHours: 0})

    let selectedCourseWithMinMaxUnits = this.selectedCourse;
    selectedCourseWithMinMaxUnits.minUnits = this.minUnits;
    selectedCourseWithMinMaxUnits.maxUnits = this.maxUnits;

    this.updateAllSelectedItems(selectedCourseWithMinMaxUnits);
  }

  updateAllSelectedItems(curriculumElementSummary: PublishedCourseSummary) : void {
    let allSelectedCurriculumItemsByMasterRecordId = this.allSelectedCurriculumItemsByMasterRecordId$.getValue();
    allSelectedCurriculumItemsByMasterRecordId.push(curriculumElementSummary.masterRecordId)
    this.allSelectedCurriculumItemsByMasterRecordId$.next(allSelectedCurriculumItemsByMasterRecordId);

  }

  removeCourse(masterRecordId: string) {

    let curriculumItemIds: string[] = this.allSelectedCurriculumItemsByMasterRecordId$.value;

    curriculumItemIds = curriculumItemIds.filter((value) => {
      return value !== masterRecordId;
    });

    this.allSelectedCurriculumItemsByMasterRecordId$.next(curriculumItemIds);
  }

  resetSelectedCurriculumItemAndUnits(){
    this.selectedCourseAndUnits$ = new Subject();
  }
}

export interface SelectedCourseWithUnits {
  course: PublishedCourseSummary;
  minUnits: number;
  maxUnits: number;
  lectureHours: number;
  labHours: number;
  minHours: number;
  maxHours: number;
}
