import { Component, Directive, OnChanges, SimpleChanges, Input, ContentChildren, ViewChildren, QueryList, ElementRef, EmbeddedViewRef, TemplateRef, ViewContainerRef } from '@angular/core';

import { TableCellDirective } from './table-cell.directive';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.css']
})
export class TableComponent implements OnChanges {
  @Input() columns: Array<{ prop?: string, columnClass?: string, columnHeaderClass?: string , title?: string, sortable?: boolean, template?: string, hideColumn?: boolean}>;
  @Input() objects: any[];
  @Input() perPage: number;
  @Input() rowClass: string | string[] | Set<string> | { [klass: string]: any; };
  @Input() initialSort: string;
  // @Input() sortDefaultValues: { prop?: string } = {};
  private _objectsLoaded = false;

  @ContentChildren(TableCellDirective) cellTemplates: QueryList<TableCellDirective>;

  @Input()
  set page(value: number) {
    this._page = value;

    this.fromIndex = this.perPage * (this.page - 1),
    this.toIndex = (this.perPage * (this.page - 1)) + this.perPage;
  }
  get page() { return this._page; }
  private _page = 1;

  private fromIndex = 0;
  private toIndex: number = this.perPage;

  sortColumn: string;
  sortDirection: SortDirection;

  get pageObjects() {
    if (this.objects) {
      return this.objects.slice(this.fromIndex, this.toIndex);
    }
  }

  ngOnChanges(inputs: SimpleChanges) {
    const firstObjectsInput = (inputs.objects &&
                               ! this._objectsLoaded &&
                               inputs.objects.previousValue === undefined &&
                               inputs.objects.currentValue);
    if (firstObjectsInput && inputs.objects.currentValue.length > 0) {
      this.sortBy(this.initialSort, true);
      this._objectsLoaded = true;
    }
  }

  getTemplate(cellTemplateName: string) {
    const matchingTemplates = this.cellTemplates.filter((tableCellDirective: TableCellDirective) => {
      return tableCellDirective.name === cellTemplateName;
    });

    if (matchingTemplates.length === 1) {
      const templateOutlet = matchingTemplates.pop();
      return templateOutlet.getTemplateRef();
    }
  }

  sortBy(prop: string, sortable: boolean) {

    // prop will be undefined when first loading table if this.initialSort is not set on table.
    if (!prop) { return false; }

    const sortFn = (a, b) => {

      let first, second, propArray;

      // This accounts for nested properties, if programs.title prop is title and propArray will have 1 string.
      // if programs.award.shortTitle, prop is award.shortTitle and propArray will have 2 strings.
      propArray = prop.split('.');

      if (propArray.length === 1) {
        first = a[propArray[0]];
        second = b[propArray[0]];
      } else if (propArray.length === 2) {
        first = a[propArray[0]][propArray[1]];
        second = b[propArray[0]][propArray[1]];
      } else {
        // console.log('Prop currently can only be 2 levels deep, such as award.shortTitle, if more levels are needed, add code here.');
      }

      if (this.sortDirection === SortDirection.Asc) {
        if (first === null) {
          return 0;
        }
        return first.toString().localeCompare(second, 'kn', {numeric: true});
      } else {
        if (second === null) {
          return 0;
        }
        return second.toString().localeCompare(first, 'kn', {numeric: true});
      }
    };

    if (!sortable) { return false; }

    this.sortColumn = prop;
    this.sortDirection = (this.sortDirection === SortDirection.Asc) ?
      SortDirection.Desc : SortDirection.Asc;

    this.objects = this.objects.sort(sortFn);
  }

  isWarning(object: any){

    if(object.severity === 'WARNING'){
      return true;
    }
    if (object.errors && object.errors.length){
      const errorCount = object.errors.filter(
        error => error.severity === 'CRITICAL'
      ).length
      const warningCount = object.errors.filter(
        error => error.severity === 'WARNING'
      ).length
      return warningCount > 0 && errorCount === 0;
    }
    if (object.programMapSummary && object.programMapSummary.errors && object.programMapSummary.errors.length){
      const errorCount = object.programMapSummary.errors.filter(
        error => error.severity === 'CRITICAL'
      ).length
      const warningCount = object.programMapSummary.errors.filter(
        error => error.severity === 'WARNING'
      ).length
      return warningCount > 0 && errorCount === 0;
    }
    return false;
  }

  isError(object: any){

    if(object.severity === 'CRITICAL'){
      return true;
    }
    if(object.error){
      return true;
    }
    if (object.errors && object.errors.length){
      const errorCount = object.errors.filter(
        error => error.severity === 'CRITICAL'
      ).length
      return errorCount > 0;
    }
    if (object.programMapSummary && object.programMapSummary.errors && object.programMapSummary.errors.length){
      const errorCount = object.programMapSummary.errors.filter(
        error => error.severity === 'CRITICAL'
      ).length
      return errorCount > 0;
    }
    return false;
  }
}

enum SortDirection {
  Asc,
  Desc,
}
