import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {AbstractControl, FormBuilder, ValidatorFn, Validators} from '@angular/forms';


import { MapControlsService } from '../map-controls/map-controls.service';

import { CustomCourseChoiceService } from '../custom-course-choice.service';
import { CustomCourseChoiceMixin } from '../custom-course-choice.mixin';
import {
  CourseReference,
  CustomCourseChoiceMapCard,
  MapCardType
} from "../../../../shared/programmapper-authoring.model";
import {CourseSelectService} from "../curriculum-item-select/course-select.service";

@Component({
  selector: 'app-custom-course-choice-update',
  templateUrl: './custom-course-choice-update.component.html',
  styleUrls: ['./custom-course-choice-update.component.css']
})
export class CustomCourseChoiceUpdateComponent extends CustomCourseChoiceMixin implements OnInit {
  nonCreditMap: boolean;

  constructor(
    protected route: ActivatedRoute,
    protected router: Router,
    protected fb: FormBuilder,
    protected courseSelectService: CourseSelectService,
    protected customCourseChoiceService: CustomCourseChoiceService,
    protected mapControlsService: MapControlsService,
  ) {
    super(route, router, fb, courseSelectService, customCourseChoiceService, mapControlsService);
  }

  ngOnInit() {
    this.customCourseChoiceMapCard = this.customCourseChoiceService.getSelectedCustomCourseListMapCard();

    if (! this.customCourseChoiceMapCard) {
      this.closeModalEmpty();
      return;
    }

    this.tableItems = this.customCourseChoiceMapCard.courseReferences;

    const selectedCourseMasterIds: string[] = this.tableItems.map((courseReference: CourseReference) =>
      courseReference.masterRecordId);

    this.courseSelectService.initialize(selectedCourseMasterIds);

    this.computeMinMaxUnits();
    this.computeMinMaxHours();


    this.route.paramMap.subscribe((paramMap: ParamMap) => {
      this.nonCreditMap = paramMap.get('nonCredit') === "true";
    });

    this.initializeForm();
  }

  initializeForm() {
    let unitsEntryDisabled: boolean;
    let specifiedUnits: number;
    let specifiedHours: number;
    if(this.customCourseChoiceMapCard.isCalculateUnits){
      unitsEntryDisabled = true;
      specifiedUnits = null;
      specifiedHours = null;
    }
    else{
      unitsEntryDisabled = false;
      specifiedUnits = this.customCourseChoiceMapCard.maxUnits;
      specifiedHours = this.customCourseChoiceMapCard.maxHours;
    }
    this.form = this.fb.group({
      title: [this.customCourseChoiceMapCard.title, Validators.required],
      longDescription: [this.customCourseChoiceMapCard.longDescription, [Validators.required, Validators.maxLength(1000)]],
      specifyUnits: [{value: !this.nonCreditMap ? specifiedUnits : specifiedHours, disabled: unitsEntryDisabled}, [Validators.required, positiveOrZeroNumberValidator()]]
    });
  }

  submitForm(): boolean {

    const customCourseGroup: CustomCourseChoiceMapCard = this.form.value;
    customCourseGroup.courseReferences = this.tableItems;
    customCourseGroup.dualRequirement = this.customCourseChoiceMapCard.dualRequirement;
    const unitsInput = this.form.get('specifyUnits');

    if (!unitsInput.disabled) {
      customCourseGroup.isCalculateUnits = false;

      if(!this.nonCreditMap) {
        customCourseGroup.minUnits = Number(unitsInput.value).valueOf();
        customCourseGroup.maxUnits = Number(unitsInput.value).valueOf();
      }
      else {
        customCourseGroup.minUnits = 0;
        customCourseGroup.maxUnits = 0;

        customCourseGroup.lectureHours = Number(unitsInput.value).valueOf();
        customCourseGroup.labHours = Number(unitsInput.value).valueOf();

        customCourseGroup.minHours = Number(unitsInput.value).valueOf();
        customCourseGroup.maxHours = Number(unitsInput.value).valueOf();
      }
    } else{
      customCourseGroup.isCalculateUnits = true;

      if(!this.nonCreditMap) {
        customCourseGroup.minUnits = Number(this.calculatedMinUnits).valueOf();
        customCourseGroup.maxUnits = Number(this.calculatedMaxUnits).valueOf();
      }
      else {
        customCourseGroup.minUnits = 0;
        customCourseGroup.maxUnits = 0;

        customCourseGroup.lectureHours = Number(this.calculatedMinHours).valueOf();
        customCourseGroup.labHours = Number(this.calculatedMinHours).valueOf();

        customCourseGroup.minHours = Number(this.calculatedMinHours).valueOf();
        customCourseGroup.maxHours = Number(this.calculatedMaxHours).valueOf();
      }
    }
    // TODO: remove this when short description is added to the form.
    customCourseGroup.shortDescription = 'Choose a course';
    customCourseGroup.type = MapCardType.CUSTOM_COURSE_CHOICE;

    this.customCourseListService.updateCustomCourseListMapCard(customCourseGroup);
    this.closeModalEmpty();
    return false;
  }
}

function positiveOrZeroNumberValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    const value = control.value;
    if(value == null || "" == value){
      return null;
    }
    return (!isNaN(value) && +value >= 0) ? null : { 'positiveOrZeroNumberValidator': true };
  };
}
